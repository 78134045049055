import * as React from "react"
import { graphql } from "gatsby"
import { Layout, Header } from '../components'
import { TypeArtwork } from "../containers/Main"


class Artwork extends React.Component {
  render() {
    const {
      data: {
        prismicOeuvres: {
          uid,
          data: {
            artist_name,
            description,
            artwork_title,
            body,
            images
          }
        }
      }
    } = this.props;

    return (
      <Layout
        context={{
          title: artwork_title.text,
          description: description.text,
          slug: `/oeuvres/${uid}/`,
        }}
      >
        <TypeArtwork>
          <Header location={this.props.location} />
          <div style={{ paddingTop: '3.2rem' }}>
            <h1>{artwork_title.text}</h1>
            <h3>{artist_name.text}</h3>
            <p dangerouslySetInnerHTML={{ __html: description.html }}></p>
            {
              images.length > 0 && images.map((item, index) => {
                return (
                  <React.Fragment key={`images-${index}`} >
                    <img 
                      data='item' 
                      src={item.image.localFile.publicURL} 
                      alt={"photo " + item.legend.text} 
                    />
                    <p className="subtitle" dangerouslySetInnerHTML={{ __html: item.legend.html }}></p>
                  </React.Fragment>
                )
              })
            }
            {
              body.length > 0 && body.map((item, index) => {
                return (
                  <React.Fragment key={`artwork-images-${index}`}>
                    <img 
                      data='item' 
                      src={item.primary.add_image.localFile.publicURL} 
                      alt={"photo " + artwork_title.text} 
                    />
                    <p className="subtitle" dangerouslySetInnerHTML={{ __html: item.primary.add_text.html }}></p>
                  </React.Fragment>
                )
              })
            }
          </div>
        </TypeArtwork>
      </Layout>
    )
  }
}

export default Artwork;
export const artworkQuery = graphql`
  query currentArtwork($slug: String!) {
    prismicOeuvres(uid: { eq: $slug }) {
      data {
        artist_name {
          text
        }
        artwork_title {
          text
        }
        description {
          html
        }
        images {
          image {
            localFile {
              publicURL
            }
          }
          legend {
            html
          }
        }
        status
        body {
          ... on PrismicOeuvresDataBodyImageTexte {
            id
            primary {
              add_image {
                localFile {
                  publicURL
                }
              }
              add_text {
                html
              }
            }
          }
        }
      }
    }
  }
`